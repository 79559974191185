import { BrowserRouter } from 'react-router-dom';
import { AllRoutes } from './index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Routes = () => {
  return (
    <BrowserRouter>
      <ToastContainer />
      <AllRoutes />
    </BrowserRouter>
  );
};

export default Routes;
