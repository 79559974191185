import Routes from "./routes/Routes";
import "./assets/scss/Theme.scss";
import { useEffect } from "react";

const App = () => {
  useEffect(() => {
    const tabIdentifier = Date.now().toString(); // Generate a unique identifier for the tab
    localStorage.setItem("tabIdentifier", tabIdentifier); // Store the identifier in localStorage

    // Listen for storage events in other tabs
    const handleStorageEvent = (event: any) => {
      if (event.key === "tabIdentifier" && event.newValue !== tabIdentifier) {
        // If another tab's identifier is different from the current tab's identifier,
        // it means the same URL is opened in another tab
        alert("This URL is already open in another tab!");
        window.location.href = "/auth/logout";
        return false;
      }
    };

    window.addEventListener("storage", handleStorageEvent);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, []);

  return <Routes />;
};

export default App;
