import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import PrivateRoute from './PrivateRoute';
import Root from './Root';

const Login = React.lazy(() => import('../pages/auth/Login'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const DashBoard = React.lazy(() => import('../pages/dashboard'));
const staffPunch = React.lazy(() => import('../pages/apps/staffPunch'));
const approvalRequest = React.lazy(() => import('../pages/tables/regulization'));
const addStaff = React.lazy(() => import('../pages/admin/addStaff'));
const regularization = React.lazy(() => import('../pages/admin/regularization'));
const staffDetails = React.lazy(() => import('../pages/admin/staffDetails'));
const attendanceReport = React.lazy(() => import('../pages/admin/report/attendance'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'auth',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                    ],
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute component={VerticalLayout} />,
            children: [
                {
                    path: 'dashboard',
                    element: <LoadComponent component={DashBoard} />,
                },
                {
                    path: 'attendance',
                    children: [
                        {
                            path: 'approval-request',
                            element: <LoadComponent component={approvalRequest} />,
                        },
                        {
                            path: 'staffattendance',
                            element: <LoadComponent component={staffPunch} />,
                        },
                    ],
                },
                {
                    path: 'admin',
                    children: [
                        {
                            path: 'add-staff',
                            element: <LoadComponent component={addStaff} />,
                        },
                        {
                            path: 'regularization',
                            element: <LoadComponent component={regularization} />,
                        },
                        {
                            path: 'staff-details',
                            element: <LoadComponent component={staffDetails} />,
                        },
                        {
                            path: 'attendance-report',
                            element: <LoadComponent component={attendanceReport} />,
                        },
                    ],
                },
            ],
        },

    ]);
};

export { AllRoutes };
