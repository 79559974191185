import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// actions
import { authApiResponseSuccess, authApiResponseError } from "./actions";

// constants
import { AuthActionTypes } from "./constants";

type UserData = {
  payload: {
    username: string;
    password: string;
    fullname: string;
    email: string;
  };
  type: string;
};

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({
  payload: { username, password },
  type,
}: UserData): SagaIterator {
  try {

    
    const url = `${process.env.REACT_APP_AUTH_SERVICE_URL}/login`;
    const body = JSON.stringify({ username, password });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_LOGIN_ACCESSTOKEN}`,
      },
      body: body,
    };

    const response = yield call(fetch, url, requestOptions);
    const apiResponse = yield response.json();

    if (apiResponse.data.length <= 0) {
      yield put(
        authApiResponseError(AuthActionTypes.LOGIN_USER, apiResponse.message)
      );
      return;
    }

    const { data } = apiResponse;
    api.setLoggedInUser(data);
    setAuthorization(data["accessToken"]);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    const url = `${process.env.REACT_APP_AUTH_SERVICE_URL}/logout`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${api.getLoggedInUser().accessToken}`,
      },
    };
    yield call(fetch, url, requestOptions);
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
  try {
    
    // const response = yield call(forgotPasswordApi, { email });
    //yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}
export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchForgotPassword),
  ]);
}

export default authSaga;
